
import { defineComponent, computed, ref } from 'vue'
import { useBreakpoints, useRootData } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/client/store'
import Dialog from 'primevue/dialog'

export default defineComponent({
  name: 'ResetPasswordModal',
  components: { Dialog },
  setup() {
    const store = useAppStore()
    const { isLoading } = useRootData()
    const { mobileSize } = useBreakpoints()
    const { t } = useI18n()

    const isConfirmationView = ref(false)

    const email = computed(() => {
      return { email: store.state.userProfile?.user.email }
    })

    const resetPassword = async () => {
      await store.dispatch('auth/resetPassword', email.value)
      isConfirmationView.value = true
    }

    return { t, mobileSize, resetPassword, isConfirmationView, isLoading }
  },
})
