
import { computed, defineComponent, ref } from 'vue'
import {
  PageContent,
  UserType,
  UserProfileBanner,
  Svg,
  TileList,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/client/store'
import { PROFILE_BOTTOM_ITEMS } from '@bd/client/config'
import ResetPasswordModal from '@bd/client/components/Profile/ResetPasswordModal.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Profile',
  components: {
    PageContent,
    UserProfileBanner,
    Svg,
    TileList,
    ResetPasswordModal,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const modalVisible = ref(false)

    const typeOfUser = ref(UserType.Buyer)

    const fetchUser = () => {
      if (!store.state.userProfile?.user.email) {
        store.dispatch('userProfile/userProfile')
      }
    }

    const user = computed(() => {
      return store.state.userProfile?.user
    })

    const bottomOptions = {
      iconWrapperColor: 'jungle-green-icon',
      iconClass: 'colorful heliotrope',
    }

    const topOptions = {
      iconWrapperColor: 'zest-icon',
      iconClass: 'colorful top-icons',
    }

    const topItems = computed(() => {
      return [
        {
          name: t('email'),
          icon: require('@bd/assets/icons/mail.svg'),
          value: user.value?.email,
        },
        {
          name: t('phone'),
          icon: require('@bd/assets/icons/phone.svg'),
          value: user.value?.phoneNumber,
        },
      ]
    })

    const onLogoutClick = async () => {
      await store.dispatch('auth/authLogout')
      router.push({ path: '/login' })
    }

    const handleItemClick = (action: string) => {
      if (action === 'reset-password') {
        modalVisible.value = true
      }
      if (action === 'logout') {
        onLogoutClick()
      }
    }
    fetchUser()

    return {
      UserType,
      typeOfUser,
      t,
      user,
      PROFILE_BOTTOM_ITEMS,
      bottomOptions,
      topOptions,
      topItems,
      handleItemClick,
      modalVisible,
    }
  },
})
