<template>
  <PageContent class="profile-content">
    <div class="header">
      <UserProfileBanner :user="user" :userType="typeOfUser" shouldShowUserId>
        <template #prepend>
          <div class="edit-button-mobile">
            <Button
              @click="$router.push({ name: 'ProfileEdit' })"
              class="p-button-secondary p-button-circle d-md-none"
            >
              <Svg
                :src="require('@bd/assets/icons/edit.svg')"
                class="edit-button-icon"
              />
            </Button>
          </div>
        </template>
        <template #append>
          <div class="edit-button-desktop">
            <Button
              @click="$router.push({ name: 'ProfileEdit' })"
              class="p-button-secondary p-button-circle d-none d-md-block"
            >
              <Svg
                :src="require('@bd/assets/icons/edit.svg')"
                class="edit-button-icon"
              />
            </Button>
          </div>
        </template>
      </UserProfileBanner>
    </div>

    <div class="list">
      <div class="list-content list-top">
        <TileList
          v-for="item in topItems"
          :key="item.name"
          :item="item"
          :opts="topOptions"
          type="advanced"
        />
      </div>

      <div class="list-content list-bottom">
        <TileList
          v-for="(item, index) in PROFILE_BOTTOM_ITEMS"
          :isBreak="!!(index % 2)"
          :key="item.name"
          :item="item"
          @item-click="handleItemClick"
          :opts="bottomOptions"
          type="simple"
        />
      </div>
    </div>
    <ResetPasswordModal v-model:visible="modalVisible" />
  </PageContent>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import {
  PageContent,
  UserType,
  UserProfileBanner,
  Svg,
  TileList,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/client/store'
import { PROFILE_BOTTOM_ITEMS } from '@bd/client/config'
import ResetPasswordModal from '@bd/client/components/Profile/ResetPasswordModal.vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Profile',
  components: {
    PageContent,
    UserProfileBanner,
    Svg,
    TileList,
    ResetPasswordModal,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const modalVisible = ref(false)

    const typeOfUser = ref(UserType.Buyer)

    const fetchUser = () => {
      if (!store.state.userProfile?.user.email) {
        store.dispatch('userProfile/userProfile')
      }
    }

    const user = computed(() => {
      return store.state.userProfile?.user
    })

    const bottomOptions = {
      iconWrapperColor: 'jungle-green-icon',
      iconClass: 'colorful heliotrope',
    }

    const topOptions = {
      iconWrapperColor: 'zest-icon',
      iconClass: 'colorful top-icons',
    }

    const topItems = computed(() => {
      return [
        {
          name: t('email'),
          icon: require('@bd/assets/icons/mail.svg'),
          value: user.value?.email,
        },
        {
          name: t('phone'),
          icon: require('@bd/assets/icons/phone.svg'),
          value: user.value?.phoneNumber,
        },
      ]
    })

    const onLogoutClick = async () => {
      await store.dispatch('auth/authLogout')
      router.push({ path: '/login' })
    }

    const handleItemClick = (action: string) => {
      if (action === 'reset-password') {
        modalVisible.value = true
      }
      if (action === 'logout') {
        onLogoutClick()
      }
    }
    fetchUser()

    return {
      UserType,
      typeOfUser,
      t,
      user,
      PROFILE_BOTTOM_ITEMS,
      bottomOptions,
      topOptions,
      topItems,
      handleItemClick,
      modalVisible,
    }
  },
})
</script>

<style lang="scss" scoped>
@import './profileStyling.scss';

:deep(.profile-content) {
  overflow-y: auto;
}
:deep(.prepend-slot) {
  position: relative;
}

.list {
  padding: 4rem 0;
  @include breakpoint-down(md) {
    padding: 1.875rem 0;
  }
}

.edit-button {
  &-icon {
    width: 14px;
    height: 14px;
  }
  &-mobile {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  &-desktop {
    position: relative;
    right: 1rem;
    top: 1rem;
  }
}
</style>
