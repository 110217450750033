<template>
  <Dialog
    maximizable
    class="dialog-password"
    header="&nbsp;"
    :class="{ 'p-dialog-maximized': mobileSize }"
    :showHeader="true"
    :modal="true"
  >
    <div class="content-wrapper">
      <div class="text-center">
        <div>
          <p class="h-1">
            {{ isConfirmationView ? t('email_sent') : t('reset_password') }}
          </p>
          <p
            class="body-bg mt-4 d-none d-md-block"
            style="padding: 0 25px 0 25px"
          >
            {{
              isConfirmationView
                ? t('email_instruction')
                : t('reset_password_button')
            }}
          </p>
        </div>
        <img
          v-if="isConfirmationView"
          src="@bd/assets/images/password-reset-confirmation.svg"
          class="reset-image"
        />
        <img
          v-else
          src="@bd/assets/images/password-reset.svg"
          class="reset-image"
        />
        <div class="d-md-none text-center">
          <p class="body-bg mt-4" style="padding: 0 25px 0 25px">
            <span v-if="isConfirmationView">{{ t('email_instruction') }}</span>

            <span v-else>{{ t('reset_password_button') }}</span>
          </p>
        </div>
      </div>

      <div class="submit-button-container flex-centered">
        <Button
          @click="resetPassword"
          :disabled="isConfirmationView || isLoading"
          class="p-button-primary px-4"
          :label="t('reset_password')"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import { useBreakpoints, useRootData } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/client/store'
import Dialog from 'primevue/dialog'

export default defineComponent({
  name: 'ResetPasswordModal',
  components: { Dialog },
  setup() {
    const store = useAppStore()
    const { isLoading } = useRootData()
    const { mobileSize } = useBreakpoints()
    const { t } = useI18n()

    const isConfirmationView = ref(false)

    const email = computed(() => {
      return { email: store.state.userProfile?.user.email }
    })

    const resetPassword = async () => {
      await store.dispatch('auth/resetPassword', email.value)
      isConfirmationView.value = true
    }

    return { t, mobileSize, resetPassword, isConfirmationView, isLoading }
  },
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  margin-bottom: 2rem;
}

:deep(.dialog-password) {
  width: min(100vw, 488px);
  @include breakpoint-up(md) {
    @include dialog-border-radius(6px);
  }
  .p-dialog-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
  .p-dialog-header-maximize {
    display: none;
  }
  .p-dialog-content {
    padding: 0;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
  .p-dialog-footer {
    @include breakpoint-up(md) {
      padding: 0 77px 40px;
    }
  }
}

.content-wrapper {
  height: 100%;
  display: flex;
  padding: 1rem 2rem 2rem 2rem;
  flex-direction: column;
  overflow-y: auto;
  @include breakpoint-down(md) {
    padding: 0;
  }
}

.submit-button-container {
  margin-top: 1rem;
  padding: 0 1rem;
  @include breakpoint-down(md) {
    margin-top: auto;
    padding: 1rem;
  }

  .submit-button {
    height: 50px;
    justify-content: center;
    width: 100%;
  }
}
.header-container {
  padding: 1rem;
}
.reset-image {
  margin: 3.125rem 0;
}
</style>
